import { Checkbox, FormControlLabel, FormGroup, Grid, Stack, TextField, Typography } from '@customink/pigment-react';
import { useEffect, useState } from 'react';
import { useData } from '~/adapter/browser/useData';
import { new_user_password_path, new_user_registration_path, new_user_session_path } from '~/adapter/window/routes';
import { CSRFHiddenInput } from '~/components/CSRFHiddenInput';
import { FlashType, RailsError, User } from '~/adapter/window/data';
import { Flash } from '~/components/Flash';
import { AuthContainer } from '~/components/AuthContainer';
import { AuthTitle } from '~/components/AuthTitle';
import { AuthText } from '~/components/AuthText';
import { Centered } from '~/components/Centered';
import { PasswordToggler } from '~/components/PasswordToggler';
import { usePassToggler } from '~/components/hooks/usePassToggler';
import { LoginAlternatives } from '~/components/LoginAlternatives';
import { FormLabel } from '~/components/FormLabel';
import { Link } from '~/components/Link';
import { FormError } from '~/components/FormError';
import { trackEvent } from '~/adapter/tracking/trackEvent';
import { trackLoginFail } from '~/adapter/tracking/loginAndSignup';
import { trackPage } from '~/adapter/tracking/trackPage';
import { persistDefaultEmail, useOneTimeDefaultEmail } from '~/components/hooks/useDefaultEmail';
import { OrDivider } from '~/components/OrDivider';
import { AuthButton } from '~/components/AuthButton';
import { trackLinksAndButtons } from '~/adapter/tracking/linksAndButtons';

function trackErrorsToGa(pageTag: string, userErrors: RailsError<User>, flash?: FlashType) {
    if (flash?.message?.match(/Email not found/)) {
        trackPage(`${pageTag}/email_error`);
    }
    if (flash?.message?.match(/Wrong password/)) {
        trackPage(`${pageTag}/password_error`);
    }
    if (flash?.message?.match(/Your account doesn't have a password/)) {
        trackPage(`${pageTag}/email_error_no_activity`);
    }
    if (flash?.message?.match(/Your account is locked/)) {
        trackPage(`${pageTag}/account_locked`);
    }
    const errors = [];
    if (flash?.type === 'error') {
        errors.push(flash.message);
    }
    if (userErrors?.password) {
        errors.push(userErrors?.password.join(', '));
    }
    if (userErrors?.email) {
        errors.push(userErrors?.email.join(', '));
    }
    if (errors.length > 0) {
        trackLoginFail('Onsite', errors.join(','));
    }
}

export function SignInWithPassword() {
    const { flash, email, errors, euRequest, redirectUri } = useData();
    const [showPass, setShowPass] = usePassToggler();
    const pageTag = flash?.type === 'error' && flash?.message ? '/accounts/loginerror' : '/accounts/login';

    useEffect(() => {
        // This is unfortunately the only way to track login errors correctly
        // Because we don't have access to all errors at single location
        trackErrorsToGa(pageTag, errors, flash);
    }, [errors, flash, pageTag]);

    const defaultEmail = useOneTimeDefaultEmail(email);
    const [userEmail, setUserEmail] = useState(defaultEmail);

    return (
        <AuthContainer>
            <Stack gap={4}>
                <Stack gap={3}>
                    <Stack gap={2}>
                        <AuthTitle>Sign In With Password</AuthTitle>

                        <AuthText>Enter your email to sign in to your Custom Ink Account.</AuthText>
                    </Stack>
                    <form action={new_user_session_path()} method="POST">
                        {redirectUri && <input type="hidden" id="redirect_uri" value={redirectUri} />}
                        <Stack gap={3}>
                            <Stack gap={2}>
                                <FormGroup>
                                    <FormLabel error={Boolean(errors.email)}>Enter email address</FormLabel>
                                    <TextField
                                        error={Boolean(errors.email)}
                                        autoFocus
                                        type="email"
                                        name="user[email]"
                                        id="user_email"
                                        defaultValue={userEmail}
                                        onChange={(ev) => setUserEmail(ev.target.value)}
                                        helperText={<FormError errors={errors.email} />}
                                        required
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <FormLabel error={Boolean(errors.password)}>
                                        <Typography variant="captionRegular">Enter password</Typography>
                                    </FormLabel>
                                    <TextField
                                        error={Boolean(errors.password)}
                                        endAdornment={<PasswordToggler show={showPass} toggle={setShowPass} />}
                                        type={showPass ? 'text' : 'password'}
                                        name="user[password]"
                                        id="user_password"
                                        helperText={<FormError errors={errors.password} />}
                                    />
                                </FormGroup>
                            </Stack>
                            <CSRFHiddenInput />

                            {flash && <Flash type={flash.type} message={flash.message} />}

                            <Grid container justifyContent={'space-between'} alignItems="center">
                                <FormGroup>
                                    <FormControlLabel
                                        control={<Checkbox size="medium" />}
                                        name="user[remember_me]"
                                        label="Remember me"
                                    />
                                </FormGroup>

                                <Link
                                    href={new_user_password_path()}
                                    underline="none"
                                    onClick={(e) => {
                                        trackEvent('Forgot Password')(e);
                                        trackPage(`${pageTag}/forget`);
                                    }}
                                >
                                    <Typography variant="bodyShort1">Forgot Password?</Typography>
                                </Link>
                            </Grid>
                            <AuthButton
                                id="login_submit"
                                variant="primary"
                                size="medium"
                                type="submit"
                                fullWidth
                                onClick={(e) => {
                                    trackEvent('Login')(e);
                                }}
                            >
                                Sign In
                            </AuthButton>
                        </Stack>
                    </form>
                </Stack>
                <OrDivider />
                <LoginAlternatives
                    euRequest={euRequest}
                    userEmail={userEmail}
                    enablePasswordLogin={false}
                    enableSocialLogin={false}
                />
                <>
                    <Centered>
                        <AuthText>
                            Don't have an account?{' '}
                            <Link
                                underline="none"
                                href={new_user_registration_path()}
                                onClick={(e) => {
                                    persistDefaultEmail(userEmail);
                                    trackLinksAndButtons('Sign Up', e);
                                }}
                            >
                                Create an account.
                            </Link>
                        </AuthText>
                    </Centered>
                </>
            </Stack>
        </AuthContainer>
    );
}
