import { Stack, Divider } from '@customink/pigment-react';
import { useEffect } from 'react';
import { AuthTitle } from '~/components/AuthTitle';
import { AuthText } from '~/components/AuthText';
import { AuthContainer } from '~/components/AuthContainer';
import { useData } from '~/adapter/browser/useData';
import { trackLoginFail } from '~/adapter/tracking/loginAndSignup';
import { create_login_code_path, login_code_sign_in_path } from '~/adapter/window/routes';
import { AuthDescription } from '~/components/AuthDecription';
import { ArrowedButton } from '~/components/ArrowedButton';
import { Link } from '~/components/Link';
import { CSRFHiddenInput } from '~/components/CSRFHiddenInput';
import { PasswordLessCode } from './PasswordLessCodeV2';

export function EnterCode() {
    const { email, flash } = useData();

    useEffect(() => {
        if (flash?.type === 'error') {
            trackLoginFail('Onsite', flash.message);
        }
    }, []);

    return (
        <AuthContainer>
            <Stack gap={3}>
                <Stack gap={2}>
                    <AuthTitle>Check Your Email for a Code</AuthTitle>
                    <AuthText>
                        We sent a 6-character code to {email}. Please type or paste the code below to sign in.
                    </AuthText>
                </Stack>
                <PasswordLessCode />
                <AuthDescription>
                    Don’t see the email in your inbox or spam folder? Try one of these options:
                </AuthDescription>
                <Stack gap={1} divider={<Divider />}>
                    <form action={create_login_code_path()} method="post">
                        <CSRFHiddenInput />
                        <input type="hidden" value={email} name="user[email]" />
                        <ArrowedButton type="submit" sx={{ pr: 2 }}>
                            Resend Code
                        </ArrowedButton>
                    </form>
                    <Link href={login_code_sign_in_path()} underline="none">
                        <ArrowedButton sx={{ pr: 2 }}>Re-enter Email Address</ArrowedButton>
                    </Link>
                </Stack>
            </Stack>
        </AuthContainer>
    );
}
