import { FormGroup, Stack, TextField } from '@customink/pigment-react';
import { useState } from 'react';
import { AuthTitle } from '~/components/AuthTitle';
import { AuthText } from '~/components/AuthText';
import { new_user_registration_path, users_sign_in_path } from '~/adapter/window/routes';
import { CSRFHiddenInput } from '~/components/CSRFHiddenInput';
import { Centered } from '~/components/Centered';
import { AuthContainer } from '~/components/AuthContainer';
import { useData } from '~/adapter/browser/useData';
import { LoginAlternatives } from '~/components/LoginAlternatives';
import { FormLabel } from '~/components/FormLabel';
import { Link } from '~/components/Link';
import { FormError } from '~/components/FormError';
import { trackEvent } from '~/adapter/tracking/trackEvent';
import { persistDefaultEmail, useOneTimeDefaultEmail } from '~/components/hooks/useDefaultEmail';
import { OrDivider } from '~/components/OrDivider';
import { trackLoginSubmit } from '~/adapter/tracking/loginAndSignup';
import { AuthButton } from '~/components/AuthButton';
import { trackLinksAndButtons } from '~/adapter/tracking/linksAndButtons';

export function SignIn() {
    const { email, errors, euRequest, redirectUri } = useData();

    const defaultEmail = useOneTimeDefaultEmail(email);
    const [userEmail, setUserEmail] = useState(defaultEmail);

    return (
        <AuthContainer>
            <Stack gap={4}>
                <Stack gap={3}>
                    <Stack gap={2}>
                        <AuthTitle>Sign In</AuthTitle>
                        <AuthText>
                            Enter your email address or choose a different way to sign in to Custom Ink.
                        </AuthText>
                    </Stack>
                    <form action={users_sign_in_path()} method="POST" id="email_submit_form">
                        <Stack gap={3}>
                            <Stack gap={2}>
                                <FormGroup>
                                    <FormLabel error={Boolean(errors.email)}>Enter email address</FormLabel>
                                    <TextField
                                        error={Boolean(errors.email)}
                                        autoFocus
                                        type="email"
                                        name="user[email]"
                                        id="user_email"
                                        defaultValue={userEmail}
                                        onChange={(ev) => setUserEmail(ev.target.value)}
                                        helperText={<FormError errors={errors.email} />}
                                        required
                                    />
                                </FormGroup>
                            </Stack>
                            {redirectUri && <input type="hidden" id="redirect_uri" value={redirectUri} />}
                            <CSRFHiddenInput />

                            <AuthButton
                                id="email_submit"
                                variant="primary"
                                size="medium"
                                type="submit"
                                fullWidth
                                onClick={(e) => {
                                    persistDefaultEmail(userEmail);
                                    trackEvent('Continue with email')(e);
                                    trackLoginSubmit('Onsite');
                                }}
                            >
                                Continue With Email
                            </AuthButton>
                        </Stack>
                    </form>
                </Stack>
                <OrDivider />
                <LoginAlternatives
                    euRequest={euRequest}
                    userEmail={userEmail}
                    enablePasswordlessLogin={false}
                    enablePasswordLogin={false}
                />
                <Centered>
                    <AuthText>
                        Don't have an account?{' '}
                        <Link
                            underline="none"
                            href={new_user_registration_path()}
                            onClick={(e) => {
                                persistDefaultEmail(userEmail);
                                trackLinksAndButtons('Sign Up', e);
                            }}
                        >
                            Create an account.
                        </Link>
                    </AuthText>
                </Centered>
            </Stack>
        </AuthContainer>
    );
}
